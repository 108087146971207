import MotionController from "./motion_controller";

export default class extends MotionController {
  static targets = ["nameDisplay", "editButton", "cancelButton", "form"]

  initialize() {
    this.showForm = this.showForm.bind(this)
    this.hideForm = this.hideForm.bind(this)
    
    if(this.hasEditButtonTarget) {
      this.hideForm()
      this.editButtonTarget.addEventListener('click', this.showForm)
      this.cancelButtonTarget.addEventListener('click', this.hideForm)
    }
  }

  showForm(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.formTarget.hidden = false
    this.nameDisplayTarget.hidden = true
    this.formTarget.querySelector('input[type=text]').focus()
  }

  hideForm(e) {
    if (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }

    this.formTarget.hidden = true
    this.nameDisplayTarget.hidden = false
  }
}