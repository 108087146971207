import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["picker", "input", "form"]

  connect() {
    this.initPicker()
  }

  disconnect() {
    this.picker.destroyAndRemove()
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,

      swatches: this.data.get("colors").split(','),

      components: {
        preview: false,
        opacity: false,
        hue: false,

        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: false,
          clear: false,
          save: false,
        },
      },
    })

    this.picker.on('swatchselect', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString()
      this.picker.applyColor(true)
      this.picker.hide()

      Rails.fire(this.formTarget, 'submit')
    });
  }
}