import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'googleSearch',
    'googleSearchInput',
    'internalSearch',
    'internalSearchInput',
    'institutionId'
  ]

  connect() {
    this.switchToGoogle = this.switchToGoogle.bind(this)
    this.switchToInternal = this.switchToInternal.bind(this)
    this.googleSearchTarget.hidden = true
  }
  
  switchToGoogle (){
    this.institutionIdTarget.value = null
    this.internalSearchTarget.hidden = true
    this.googleSearchTarget.hidden = false
    this.googleSearchInputTarget.value = this.internalSearchInputTarget.value

    this.googleSearchInputTarget.focus()
  }

  switchToInternal (){
    this.institutionIdTarget.value = null
    this.internalSearchTarget.hidden = false
    this.googleSearchTarget.hidden = true
    this.internalSearchInputTarget.value = this.googleSearchInputTarget.value

    this.element.querySelectorAll('[data-target^="places."]').forEach((el) => el.value = '' )

    this.internalSearchInputTarget.focus()
    const event = new Event('input')
    this.internalSearchInputTarget.dispatchEvent(event)
  }

}