import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.submitForm()
  }

  disconnect() {
  }

  submitForm () {
    if (this.element.dataset.remote === undefined) {
      this.element.submit()
    } else {
      Rails.fire(this.element, 'submit')
    }
  }
}