import MotionController from "./motion_controller";

export default class extends MotionController {
  static targets = ['lineday', 'output', 'overlapAlert']

  initialize() {
    this.reset = this.reset.bind(this)
    this.readState = this.readState.bind(this)
    this.restoreState = this.restoreState.bind(this)
    this.checkOverlap = this.checkOverlap.bind(this)
   
    $('#day_bonds_modal').on('hidden.bs.modal', this.reset)
    this.submitButton = document.querySelector('#day_bonds_modal .submit_form')
    
    this.planningId = this.data.get('planningId')
    this.dayBondsCount = parseInt(this.data.get('dayBondsCount'))

    this.maxConstraintOrder = 7

    this.initializeLineday = this.initializeLineday.bind(this)
    this.initializeLinedays = this.initializeLinedays.bind(this)
    this.lineDayClicked = this.lineDayClicked.bind(this)
    this.lineDayHover = this.lineDayHover.bind(this)
    this.lineDayOut = this.lineDayOut.bind(this)
    this.startSelectionMode = this.startSelectionMode.bind(this)
    this.stopSelectionMode = this.stopSelectionMode.bind(this)
    this.removeElementFromSelection = this.removeElementFromSelection.bind(this)
    this.addElementToSelection = this.addElementToSelection.bind(this)
    this.saveDayBonds = this.saveDayBonds.bind(this)
    
    this.submitButtonToolip = $(this.submitButton).parents('.modal-footer').tooltip({
      title: I18n.t('plannings.day_bonds_modal.tooltip.edition_mode'),
      placement: 'right'
    })
    this.stopSelectionMode()
    this.initializeLinedays()
    this.overlapAlertTarget.hidden = true

    this.readState()
  }
  
  readState () {
    this.state = {}
    this.linedayTargets.forEach(element => {
      let constraintId = element.dataset.constraintId
      this.state[element.getAttribute('id')] = constraintId
    })
  }

  restoreState () {
    this.linedayTargets.forEach(element => {
      element.dataset.constraintId = this.state[element.getAttribute('id')]
    })
  }

  reset (){
    this.restoreState()
    this.stopSelectionMode()
    this.linedayTargets.forEach(element => {
      element.querySelector('input').checked = false
    })
  }

  initializeLinedays () {
    this.linedayTargets.forEach(element => {
      this.initializeLineday(element)
    })
  }

  initializeLineday (element) {
    element.addEventListener('click', this.lineDayClicked)
    element.addEventListener('mouseover', this.lineDayHover)
    element.addEventListener('mouseout', this.lineDayOut)
  }

  lineDayClicked (e) {
    const element = e.currentTarget
    e.preventDefault()
    e.stopImmediatePropagation()

    if(this.selectionMode) {
      if(element == this.initialSelectionElement) {
        // you cannot unselect the initial element
        return
      }

      if(element.classList.contains('active')) {
        this.removeElementFromSelection(element)
      }
      else {
        const constraintId = element.dataset.constraintId
        if(constraintId == '') {
          this.addElementToSelection(element)
        }
      }
    }
    else {
      this.startSelectionMode(element)
    }
  }

  lineDayHover  (e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    if(this.selectionMode) {
    }
    else {
      const element = e.currentTarget
      const constraintId = element.dataset.constraintId

      element.classList.add('linked')

      if(constraintId == '') {
        return
      }

      this.element.querySelectorAll('[data-constraint-id="'+ constraintId +'"]').forEach(element => {
        element.classList.add('linked')
      })
    }
  }
  
  lineDayOut (e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.element.querySelectorAll('.linked').forEach(element => {
      element.classList.remove('linked')
    })
  }

  startSelectionMode (element) {
    this.selectionMode = true
    this.element.classList.add('selection-mode')

    this.submitButton.classList.add('disabled')
    this.submitButton.setAttribute('disabled', true)

    this.submitButtonToolip.tooltip('enable')
    this.submitButtonToolip.tooltip('show')

    this.selectedElements = []
    this.constraintId = element.dataset.constraintId
    if(this.constraintId == '') {
      this.constraintId = element.getAttribute('id')
      element.dataset.constraintOrder = this.dayBondsCount % this.maxConstraintOrder
      this.dayBondsCount += 1
    }

    this.initialSelectionElement = element
    this.addElementToSelection(element)

    element.classList.add('initial-selection')
    element.querySelector('button').addEventListener('click', this.stopSelectionMode)

    this.linedayTargets.forEach(other => {
      this.markUnavailable(other)
    })
    
    this.element.querySelectorAll('[data-constraint-id=""]').forEach(other => {
      this.markAvailable(other)
    })
    
    this.element.querySelectorAll('[data-constraint-id="'+ this.constraintId +'"]').forEach(other => {
      this.markAvailable(other)
      if(other != element){
        this.addElementToSelection(other)
      }
    })
  }

  stopSelectionMode (e) {
    if(e) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }

    this.submitButton.classList.remove('disabled')
    this.submitButton.removeAttribute('disabled')
    this.submitButtonToolip.tooltip('disable')
    this.overlapAlertTarget.hidden = true

    if(this.initialSelectionElement) {
      this.initialSelectionElement.querySelector('button').removeEventListener('click', this.stopSelectionMode)
      this.initialSelectionElement.classList.remove('initial-selection')
      if(this.selectedElements.length == 1) {
        this.removeElementFromSelection(this.initialSelectionElement)
      }
    }
    this.selectionMode = false
    this.element.classList.remove('selection-mode')

    this.element.querySelectorAll('.active').forEach(element => {
      element.classList.remove('active')
    })

    this.saveDayBonds()

    return false
  }

  addElementToSelection (element) {
    this.selectedElements.push(element)
    element.classList.add('active')
    element.querySelector('input').checked = true
    element.dataset.constraintId = this.constraintId
    element.dataset.constraintOrder = this.initialSelectionElement.dataset.constraintOrder % this.maxConstraintOrder
    this.checkOverlap()
  }
  
  removeElementFromSelection (element) {
    this.selectedElements.splice(this.selectedElements.indexOf(element),1)
    element.classList.remove('active')
    element.querySelector('input').checked = false
    element.dataset.constraintId = ''
    element.dataset.constraintOrder = ''
    this.checkOverlap()
  }

  markUnavailable (element) {
    element.classList.add('unavailable')
    element.querySelector('input').disabled = true
  }
  
  markAvailable (element) {
    element.classList.remove('unavailable')
    element.querySelector('input').disabled = false
  }

  checkOverlap () {
    this.overlapAlertTarget.hidden = true
    
    if(this.selectedElements.length < 2) {
      return
    } 
    
    let forbiddenLines = []
    
    this.selectedElements.forEach(element => {
      forbiddenLines = forbiddenLines.concat(JSON.parse(element.dataset.lineOverlapsWith))
    })
        
    this.selectedElements.forEach(element => {
      let key = element.dataset.lineId + '-' + element.dataset.wday
      if(forbiddenLines.indexOf(key) > -1){
        this.overlapAlertTarget.hidden = false
        return
      }
    })
  }

  saveDayBonds () {
    let dayBonds = {} 

    this.linedayTargets.forEach(element => {
      let constraintId = element.dataset.constraintId
      if(constraintId != '') {
        let id = element.getAttribute('id')
        if( !dayBonds[constraintId] ){
          dayBonds[constraintId] = []
        }
        dayBonds[constraintId].push(id)
      }
    })

    this.outputTarget.value = JSON.stringify(dayBonds)
    this.readState()
  }

}