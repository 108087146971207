import { Controller } from "stimulus"

export default class extends Controller {
  select() {
    $("#institution_lifen_reference").val(this.data.get("lifen-reference"))
    $("#institution_lifen_name").val(this.data.get("lifen-name"))
    $("#institution_hubspot_id").val(this.data.get("hubspot-id"))

    $("#institution_hubspot_id").parents("form").submit()
  }

}
