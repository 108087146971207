import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.submitForm = this.submitForm.bind(this)

    this.inputTargets.forEach((el) => {
      el.addEventListener('change', this.submitForm)

      if (el.classList.contains('select2-hidden-accessible')) {
        $(el).select2().on('change', this.submitForm)
      }
    })
  }

  disconnect() {
  }

  submitForm () {
    if (this.element.dataset.remote === undefined) {
      this.element.submit()
    } else {
      Rails.fire(this.element, 'submit')
    }
  }
}