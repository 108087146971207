import MotionController from "./motion_controller";

export default class extends MotionController {
  static targets = [
    'linesSelector',
    'gardesList',
    'lineToggle',
    'filtersForm',
    'garde',
    'membersSelector',
    'selectionSize',
    'members',
    'membersLoader',
    'membersSearch',
    'gardesContainer',
    'framepicker',
    'unassignButton',
    'loader',
    'error'
  ]

  initialize() {
    this.currentSelection = []

    this.submitFiltersForm = this.submitFiltersForm.bind(this) 
    this.toggleGardeSelection = this.toggleGardeSelection.bind(this) 
    this.toggleGardeHover = this.toggleGardeHover.bind(this) 
    this.toggleGardeOut = this.toggleGardeOut.bind(this) 
    this.toggleLine = this.toggleLine.bind(this) 
    this.selectGarde = this.selectGarde.bind(this) 
    this.unselectGarde = this.unselectGarde.bind(this) 
    this.unassignSelection = this.unassignSelection.bind(this) 
    this.cancelSelection = this.cancelSelection.bind(this) 
    this.updateSelection = this.updateSelection.bind(this) 
    this.submitSelection = this.submitSelection.bind(this) 
    this.searchMembers = this.searchMembers.bind(this) 
    this.resetSearchMembersCooldown = this.resetSearchMembersCooldown.bind(this) 
    this.updateMembers = this.updateMembers.bind(this) 
    this.keyDown = this.keyDown.bind(this) 
    this.keyUp = this.keyUp.bind(this) 
    this.startMultipleSelection = this.startMultipleSelection.bind(this) 
    this.abortMultipleSelection = this.abortMultipleSelection.bind(this) 
    this.changedSelectedMember = this.changedSelectedMember.bind(this) 
    this.scrollToMembers = this.scrollToMembers.bind(this) 
    this.showError = this.showError.bind(this) 
    this.showLoader = this.showLoader.bind(this) 
    this.highlightMember = this.highlightMember.bind(this)
    this.blurMember = this.blurMember.bind(this)
    this.showMembers = this.showMembers.bind(this)
    this.fadeAllMembers = this.fadeAllMembers.bind(this)

    this.submitChangesButton = this.membersSelectorTarget.querySelector('.btn-success')
    
    this.lineToggleTargets.forEach(element => {
      element.addEventListener('change', this.toggleLine)
    })

    this.gardeTargets.forEach(element => {
      element.addEventListener('click', this.toggleGardeSelection)
      element.addEventListener('mouseenter', this.toggleGardeHover)
      element.addEventListener('mouseleave', this.toggleGardeOut)
    })

    this.framepickerTarget.addEventListener('change', this.submitFiltersForm)
    this.membersSearchTarget.value = ''
    this.membersSearchTarget.addEventListener('keyup', this.resetSearchMembersCooldown)
    document.addEventListener('keydown', this.keyDown)
    document.addEventListener('keyup', this.keyUp)

    $('[data-toggle="tooltip"]', this.element).tooltip('dispose')
    $('[data-toggle="tooltip"]', this.element).tooltip()

    this.positionLineLabels()
    this.toggleLine()
  }

  highlightMember (e) {
    if(e){
      this.stopEvent(e)
    }

    if(this.selectedMember){
      return
    }

    if(this.blurMemberTimeout) {
      clearTimeout(this.blurMemberTimeout)
    }
    
    let target = e.currentTarget
    let id = target.dataset.member_id

    if(id == this.highlightedId) {
      return
    }

    this.highlightedId = id
    
    if(id === undefined || id.length == 0) {
      return
    }
    
    this.element.querySelectorAll('.line.faded').forEach((el) => {
      el.classList.remove('faded')
    })
    this.element.querySelectorAll('.line:not(.member-' + id + ')').forEach((el) => {
      el.classList.add('faded')
    })
  }

  fadeAllMembers (e) {
    this.element.querySelectorAll('.line').forEach((el) => {
      el.classList.add('faded')
    })
  }

  blurMember (e) {   
    if(e){
      this.stopEvent(e)
    }
    
    if(this.selectedMember){
      return
    }

    if(this.currentSelection.length > 0) {
      this.fadeAllMembers()
      return
    }

    this.highlightedId = null

    this.element.querySelectorAll('.line.faded').forEach((el) => {
      el.classList.remove('faded')
    })
  }

  toggleLine (e) {
    if(e){
      this.stopEvent(e)
    }
    if ('URLSearchParams' in window) {
      let formData = new FormData(document.querySelector('[data-target="gardes-editor.filtersForm"]'))
      var searchParams = new URLSearchParams();
      for (var pair of formData.entries()) {
        searchParams.set(pair[0], pair[1])
      }
      window.history.replaceState(null,"", '?' + searchParams.toString())
    } 
  }

  scrollToMembers () {
    const offset = this.element.getBoundingClientRect().top - 60

    if(offset < 0) {
      window.scrollBy({
        top: offset,
        behavior: 'smooth'
      })
    }
  }

  showError () {
    this.errorTarget.hidden = false
    this.loaderTarget.hidden = true
  }

  showLoader () {
    this.loaderTarget.hidden = false
  }

  isMac () {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0
  }

  keyDown (e) {
    if(e.key == 'Shift') {
      this.shiftMode = true
      this.startMultipleSelection()
    }
    if(!this.isMac() && e.key == 'Control') {
      this.ctrlMode = true
    }
    if(this.isMac() && e.key == 'Meta') {
      this.ctrlMode = true
    }
  }
  
  keyUp (e) {
    if(e.key == 'Escape') {
      this.cancelSelection(e)
    }
    if(e.key == 'Shift') {
      this.shiftMode = false
      this.abortMultipleSelection()
    }
    if(!this.isMac() && e.key == 'Control') {
      this.ctrlMode = false
    }
    if(this.isMac() && e.key == 'Meta') {
      this.ctrlMode = false
    }
  }

  positionLineLabels () {
    let offset = this.gardesListTarget.getBoundingClientRect().top

    this.element.querySelectorAll('.lines-labels > *').forEach((el) => {
      let ref = this.element.querySelector(`[data-line-label="${el.classList[0]}"]`)
      if(ref) {
        el.style.top = ref.getBoundingClientRect().top - offset + 'px'
      }
    })
  }

  submitFiltersForm (e) {
    this.stopEvent(e)

    this.filtersFormTarget.submit()
  }

  toggleGardeHover (e){
    this.stopEvent(e)

    if(this.currentSelection.length == 0){
      this.highlightMember(e)
    }

    this.latestGardeHovered = e.currentTarget
    this.element.querySelector(`.${e.currentTarget.dataset.lineLabel}`).classList.add('visible')
    
    if(!this.latestGardeSelected || !this.shiftMode) {
      return
    }
    this.startMultipleSelection()
  }

  toggleGardeOut (e){
    this.stopEvent(e)

    if(this.currentSelection.length == 0){
      if(this.blurMemberTimeout) {
        clearTimeout(this.blurMemberTimeout)
      }
      this.blurMemberTimeout = setTimeout(this.blurMember, 200)
    }

    this.element.querySelectorAll(`.line-label.visible`).forEach((el) => {
      el.classList.remove('visible')
    })
  }

  abortMultipleSelection (){
    this.multipleSelection = []
    this.gardeTargets.forEach(element => {
      element.classList.remove('hover')
    })
  }

  startMultipleSelection (){
    if(!this.latestGardeSelected || !this.latestGardeHovered) {
      return
    }
    const rangeX = [parseInt(this.latestGardeHovered.dataset.x), parseInt(this.latestGardeSelected.dataset.x)].sort((a, b) => a - b)
    const rangeY = [parseInt(this.latestGardeHovered.dataset.y), parseInt(this.latestGardeSelected.dataset.y)].sort((a, b) => a - b)

    this.multipleSelection = []

    this.gardeTargets.forEach(element => {
      if(element == this.latestGardeSelected) { return }
  
      let hover = ! element.hidden
      hover = hover && element.dataset.enabled == 'true'
      hover = hover && element.dataset.x >= rangeX[0] && element.dataset.x <= rangeX[1]
      hover = hover && element.dataset.y >= rangeY[0] && element.dataset.y <= rangeY[1]
      element.classList.toggle('hover', hover)
      if(hover) {
        this.multipleSelection.push(element)
      }
    })
  }
  
  toggleGardeSelection (e){
    this.stopEvent(e)

    if(e.currentTarget.dataset.enabled != 'true') {
      return
    }

    if(this.shiftMode) {
      this.multipleSelection.forEach(this.selectGarde)
      this.multipleSelection = []
      this.latestGardeSelected = null
      return
    }

    if(e.currentTarget.classList.contains('selected')){
      this.unselectGarde(e.currentTarget)
      this.updateSelection()
    }
    else {
      this.selectGarde(e.currentTarget)
    }

    return false
  }

  selectGarde (garde){
    if(!this.shiftMode && !this.ctrlMode) {
      this.cancelSelection()
      this.scrollToMembers()
    }

    const gardeId = garde.dataset.value 
    garde.classList.add('selected')
    this.currentSelection.push(gardeId)
    this.updateSelection()
    this.latestGardeSelected = garde
  }
  
  unselectGarde (garde){
    const gardeId = garde.dataset.value 
    garde.classList.remove('selected')
    this.currentSelection.splice(this.currentSelection.indexOf(gardeId), 1)
    this.latestGardeSelected = null
  }
  
  cancelSelection (e){
    if(e) {
      this.stopEvent(e)
    }
    
    this.currentSelection = []
    this.gardeTargets.forEach(this.unselectGarde)
    this.membersSearchTarget.value = ''
    this.latestGardeSelected = null
    this.showAllMembers = false
    this.selectedMember = null
    this.blurMember()
    if(e) {
      this.updateSelection()
    }
  }

  postMassAssignment(unpublished_user_id) {
    let _showError = this.showError
    $.post(
      this.element.dataset.updateUrl,
      {
        mass_assignment: {
          unpublished_user_id: unpublished_user_id,
          garde_ids: this.selectionToBePosted 
        }
      }
    ).fail((response) => {
      _showError()
    })
  }

  changedSelectedMember (e){
    this.stopEvent(e)

    this.selectedMember = null
    this.highlightedId = null
    this.blurMember(e)
    this.highlightMember(e)
    this.selectedMember = true

    this.submitChangesButton.removeAttribute('disabled')
    this.submitChangesButton.classList.remove('disabled')
  }

  submitSelection (e){
    this.stopEvent(e)
 
    const selected = this.membersTarget.querySelector(':checked')
    if(!selected) {
      return
    }
    
    this.showLoader()
    const unpublished_user_id = selected.value;
    
    e.currentTarget.setAttribute('disabled', true)
    e.currentTarget.classList.add('disabled')

    this.selectionToBePosted = this.currentSelection
    this.currentSelection = []
    this.hideMembersSelectorTarget()
    
    this.postMassAssignment(unpublished_user_id)
  }

  unassignSelection (e){
    this.stopEvent(e)
    
    this.showLoader()
    e.currentTarget.setAttribute('disabled', true)
    e.currentTarget.classList.add('disabled')

    this.selectionToBePosted = this.currentSelection
    this.currentSelection = []
    this.hideMembersSelectorTarget()

    this.postMassAssignment(null)
  }

  memberSearchUrl (){
    let url = this.membersSelectorTarget.dataset.fetchUrl + '?q=' + this.membersSearchTarget.value 
    this.currentSelection.forEach((id) => {
      url = url + "&garde_ids[]=" + id
    })
    return url
  }

  hideMembersSelectorTarget (){
    if(!this.filtersFormTarget.hidden) {
      return
    }

    if(this.lockAnimations) {
      return
    }

    this.lockAnimations = true
    this.filtersFormTarget.hidden = false
    this.element.querySelector('.filters').classList.remove('members-mode')
    this.membersSelectorTarget.style.top = ''
    
    this.showAllMembers = false
    this.selectedMember = null
    this.blurMember()

    requestAnimationFrame(function() {
      this.filtersFormTarget.style.opacity = ''
    }.bind(this))
    
    setTimeout(function() {
      this.lockAnimations = false

      this.updateSelection()
    }.bind(this), 500)
  }
  
  showMembers (e) {
    this.showAllMembers = true
    this.unassignButtonTarget.hidden = true
    this.showMembersSelectorTarget()
    this.resetSearchMembersCooldown()
  }

  showMembersSelectorTarget (){
    if(this.filtersFormTarget.hidden) {
      this.resetSearchMembersCooldown()
      return
    }

    if(this.lockAnimations) {
      return
    }
   
    this.resetSearchMembersCooldown()
      
    this.submitChangesButton.hidden = this.currentSelection.length == 0

    this.lockAnimations = true

    if(!this.filtersFormTarget.hidden){
      this.membersSelectorTarget.style.minHeight = this.filtersFormTarget.getBoundingClientRect().height + 'px'
      this.submitChangesButton.setAttribute('disabled', true)
      this.submitChangesButton.classList.add('disabled')
    }
    requestAnimationFrame(function() {
      this.membersSelectorTarget.style.top = '0'
      this.filtersFormTarget.style.opacity = '0.1'
    }.bind(this))

    setTimeout(function() {
      this.filtersFormTarget.hidden = true
      this.element.querySelector('.filters').classList.add('members-mode')
      this.lockAnimations = false

      this.updateSelection()
    }.bind(this), 500)
  }

  updateSelection (){
    if(this.currentSelection.length == 0 && !this.showAllMembers){
      this.hideMembersSelectorTarget()
    }
    else {
      this.fadeAllMembers()

      this.showMembersSelectorTarget()
      
      let anyAssigned = false
      this.currentSelection.forEach((id) => {
        let el = this.element.querySelector(`[data-value="${id}"]`)
        anyAssigned = anyAssigned || !el.classList.contains('unassigned')
      })
      this.unassignButtonTarget.hidden = !anyAssigned
    }
    this.selectionSizeTarget.innerHTML = this.currentSelection.length
  }

  resetSearchMembersCooldown (){
    if(this.searchMembersCooldown){
      clearTimeout(this.searchMembersCooldown)
    }

    this.searchMembersCooldown = setTimeout(this.searchMembers, 200)
  }

  searchMembers (){
    this.searchMembersCooldown = null
    this.selectedMember = null

    fetch(this.memberSearchUrl()).then((res) => {
      return res.json()
    }).then(this.updateMembers)
  }

  updateMembers (res){
    this.membersLoaderTarget.hidden = true
    this.membersTarget.innerHTML = ''
    const total = res.length
    const limit = parseInt((this.membersSelectorTarget.clientHeight - 149) / 58) * 4

    res.forEach((data, i) => {
      if(i == limit - 1){
        let newMember = document.createElement("div")
        newMember.classList.add('member')
        newMember.classList.add('more')
        let htmlString = `<div class='inner'>
          <strong>${I18n.t('plannings.gardes.index.and_more',  {count: total - limit + 1})}</strong>
        </div>`
        newMember.innerHTML = htmlString
        this.membersTarget.appendChild(newMember)
        newMember.addEventListener('click', (e) => {
          $('.member').removeAttr('hidden')
          e.currentTarget.hidden = true
        })
      }

      let newMember = document.createElement("div")
      newMember.setAttribute('data-member_id', data.id)
      newMember.classList.add('member')
      if(data.unavailable) {
        newMember.classList.add('unavailable')
        newMember.setAttribute('data-toggle', 'tooltip')
        newMember.setAttribute('data-trigger', 'hover')
        newMember.setAttribute('title', I18n.t('plannings.gardes.index.unavailable_member'))
        $(newMember).tooltip()
      }
      if(data.rest) {
        newMember.classList.add('unavailable')
        newMember.setAttribute('data-toggle', 'tooltip')
        newMember.setAttribute('data-trigger', 'hover')
        newMember.setAttribute('title', I18n.t('plannings.gardes.index.resting_member'))
        $(newMember).tooltip()
      }
      if(data.taken) {
        newMember.classList.add('unavailable')
        newMember.setAttribute('data-toggle', 'tooltip')
        newMember.setAttribute('data-trigger', 'hover')
        newMember.setAttribute('title', I18n.t('plannings.gardes.index.taken_member'))
        $(newMember).tooltip()
      }
      else if(data.wish) {
        newMember.classList.add('wish')
        newMember.setAttribute('data-toggle', 'tooltip')
        newMember.setAttribute('data-trigger', 'hover')
        newMember.setAttribute('title', I18n.t('plannings.gardes.index.wishing_member'))
        $(newMember).tooltip()
      }
      else if (i == 0 && this.currentSelection.length > 0){
        newMember.classList.add('top-pick')
        newMember.setAttribute('data-toggle', 'tooltip')
        newMember.setAttribute('data-trigger', 'hover')
        newMember.setAttribute('title', I18n.t('plannings.gardes.index.top_pick'))
        $(newMember).tooltip()
      }
      let htmlString = `<div class='inner w-100'>
        <div class='flex-shrink-0 d-flex flex-column justify-content-center' >
          <input 
            type='radio' 
            id='member-${ data.id }' 
            data-member_id=${ data.id } 
            value=${ data.id } 
            name='member_id'
          >
        </div>
        <label class='m-0 pl-2 overflow-hidden flex-grow-1 d-flex flex-column justify-content-center' for='member-${ data.id }' >
          <strong class='text-truncate'>${data.name}</strong>
        </label>
        <div class='flex-shrink-0 pull-right ml-3 d-flex flex-column justify-content-center' >
          <span class='badge badge-primary px-2'>${data.gardes_count}</span>
        </div>
      </div>`
      newMember.innerHTML = htmlString
      if(i >= limit - 1){
        newMember.setAttribute('hidden', true)
      }
      this.membersTarget.appendChild(newMember)
      newMember.addEventListener('mouseenter', this.highlightMember)
      newMember.addEventListener('mouseleave', this.blurMember)
    })

    this.membersTarget.querySelectorAll('input[type=radio]').forEach(element => {
      element.addEventListener('change', this.changedSelectedMember)
    })

    this.membersTarget.hidden = false
  }
 
}