import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'input',
    'icon'
  ]

  connect() {
  }

  toggle (event) {
    event.preventDefault()
    if(this.inputTarget.getAttribute("type") == "text"){
      this.inputTarget.setAttribute('type', 'password')
      this.iconTarget.classList.remove( "fa-eye-slash" )
      this.iconTarget.classList.add( "fa-eye" )
    } else {
      this.inputTarget.setAttribute('type', 'text')
      this.iconTarget.classList.add( "fa-eye-slash" )
      this.iconTarget.classList.remove( "fa-eye" )
    }
  }
 
}