import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'status'
  ]

  connect() {
    this.toggle = this.toggle.bind(this)
    this.statusTarget.addEventListener('change', this.toggle)
    this.toggle()
  }

  toggle() {
    let selected_status = this.statusTarget.value

    if (selected_status == "interne") {
      $(".medecin_attributes").hide()
      $(".interne_attributes").show()
      activate_select2()
    } else if (selected_status == "medecin") {
      $(".interne_attributes").hide()
      $(".medecin_attributes").show()
      activate_select2()
    } else {
      $(".interne_attributes").hide()
      $(".medecin_attributes").hide()
    }
  }

}
