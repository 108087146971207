import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "googleCityName", "googleCityPostalCode", "googleCountryName", "googlePlaceId", "googleName", "googleFormattedAddress", "googleLat", "googleLng", "googleHealthEstablishment", "googleCountryCode", "googleAdministrativeAreaName", "careServiceSelector", "careServiceName"]

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }

  }

  initMap() {
  
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get("latitude") || 46.528, this.data.get("longitude") || 2.438),
      zoom: (this.data.get("latitude") == null ? 5 : 15),
      mapTypeControlOptions: { mapTypeIds: [] },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      streetViewControl: false
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.bindTo('bounds', this.map)
    // this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.setTypes(["establishment"])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    this.infowindow = new google.maps.InfoWindow()

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })

    this.showMarker()
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()

    this.infowindow.close()

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    this.showMarker(place)
    this.extractDatas(place)
    this.resetCareService()

    $.ajax("/care_services", { data: { google_place_id: place.place_id }, dataType: "script" });
  }

  showMarker(place) {
    if(!place) {
      return
    }
    this.mapTarget.style.display = 'block'
    this.marker.setVisible(false)

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
    }

    this.map.setZoom(16)

    this.marker.setIcon(({
      url: place.icon,
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(35, 35)
    }))
    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    let address = ''
    if (place.address_components) {
      address = [
        (place.address_components[0] && place.address_components[0].short_name || ''),
        (place.address_components[1] && place.address_components[1].short_name || ''),
        (place.address_components[2] && place.address_components[2].short_name || '')
      ].join(' ')
    }

    this.infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address)
    this.infowindow.open(this.map, this.marker)
  }

  extractDatas(place) {
    let city = "";
    let country = "";
    let postal_code = "";
    let administrative_area = "";

    if (place.address_components) {
      for (var i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types[0] == "locality") {
          city = place.address_components[i];
        } else if (place.address_components[i].types[0] == "country") {
          country = place.address_components[i];
        } else if (place.address_components[i].types[0] == "administrative_area_level_1") {
          administrative_area = place.address_components[i];
        } else if (place.address_components[i].types[0] == "postal_code") {
          postal_code = place.address_components[i];
        }
      }
    }

    let health_establishment = place.types.indexOf("health") > -1;


    if (this.hasGoogleCityNameTarget) {
      this.googleCityNameTarget.value = city.long_name
    }
    if (this.hasGoogleCityPostalCodeTarget) {
      this.googleCityPostalCodeTarget.value = postal_code.short_name
    }
    if (this.hasGoogleCountryCodeTarget) {
      this.googleCountryCodeTarget.value = country.short_name
    }
    if (this.hasGoogleCountryNameTarget) {
      this.googleCountryNameTarget.value = country.long_name
    }
    if (this.hasGoogleAdministrativeAreaNameTarget) {
      this.googleAdministrativeAreaNameTarget.value = administrative_area.long_name
    }

    if (this.hasGooglePlaceIdTarget) {
      this.googlePlaceIdTarget.value = place.place_id
    }
    if (this.hasGoogleNameTarget) {
      this.googleNameTarget.value = place.name
    }
    if (this.hasGoogleFormattedAddressTarget) {
      this.googleFormattedAddressTarget.value = place.formatted_address
    }

    if (this.hasGoogleLatTarget) {
      this.googleLatTarget.value = place.geometry.location.lat()
    }
    if (this.hasGoogleLngTarget) {
      this.googleLngTarget.value = place.geometry.location.lng()
    }

    if (this.hasGoogleHealthEstablishmentTarget) {
      this.googleHealthEstablishmentTarget.value = health_establishment
    }

  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }

  resetCareService() {
    if (!this.hasCareServiceSelectorTarget) {
      return
    }

    this.careServiceSelectorTarget.visibility = false
  }

  showCareServiceName() {
    if (!this.hasCareServiceNameTarget) {
      return
    }

    this.careServiceNameTarget.disabled = false
    this.careServiceNameTarget.focus()
  }

  selectPlaceId(placeId) {
    let service = new google.maps.places.PlacesService(this.map)

    const controller = this

    service.getDetails({
      placeId: placeId
    }, function (place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        controller.showCareServiceName()
        controller.showMarker(place)
        controller.extractDatas(place)
      }
    })
  }

  selectQuery(query) {
    let service = new google.maps.places.PlacesService(this.map)

    const controller = this

    service.textSearch({
      query: query
    }, function (places, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK && places.length > 0) {
        place = places[0];
        controller.showMarker(place)
        controller.extractDatas(place)
      }
    })
  }
}