/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import $ from 'jquery'
global.$ = $
global.jQuery = $

import Rails from '@rails/ujs'
Rails.start()
window.Rails = Rails

import 'fullcalendar'
import 'fullcalendar/dist/locale/fr'
import 'jquery-ui'
import 'jquery.cookie'
import 'bootstrap'
import 'bootstrap4-toggle'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tooltip'
import 'data-confirm-modal'

import '@stimulus/polyfills'
import 'weakmap-polyfill'
import 'custom-event-polyfill'
import 'select2'
import 'select2/src/js/select2/i18n/fr'
import 'bootstrap-table'

import moment from 'moment'
window.moment = moment

import motion from 'motion'
window.App = { cable: motion.consumer }
window.motion = motion

import { Application } from "stimulus"
import { Autocomplete } from 'stimulus-autocomplete'

import { definitionsFromContext } from "stimulus/webpack-helpers"
import I18n from "../helpers/18n.js.erb"
import "../helpers/jquery.ui.datepicker-fr"

window.I18n = I18n

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
const context_components = require.context("../../components", true, /_controller.js$/)

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
)

application.register('autocomplete', Autocomplete)

window.initMap = function (...args) {
  const event = new CustomEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

I18n.defaultLocale = document.querySelector("meta[name='i18n-default-locale']").getAttribute("content") || 'fr';
I18n.locale = document.querySelector("html").getAttribute("lang") || 'fr';
I18n.fallbacks = true;

function initialize_datepickers () {
  $.datepicker.setDefaults( $.datepicker.regional[ I18n.locale ])
  $.datepicker.setDefaults({ autoclose: true })

  activate_bootstrap_datepickers()
}

$(document).on('turbolinks:load', initialize_datepickers);
$(document).ready(initialize_datepickers);