import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['masterInput', 'slaveInput', 'requiredInput', 'submitInput']

  connect() {
    this.prefillSlaves = this.prefillSlaves.bind(this)
    this.toggleDisable = this.toggleDisable.bind(this)

    this.lockedSlaves = []

    if (this.hasMasterInputTarget) {
      this.masterInputTarget.addEventListener('keyup', this.prefillSlaves)
    }

    this.slaveInputTargets.forEach((input) => {
      input.addEventListener('change', function() {
        if(this.lockedSlaves.indexOf(input) >= 0) {
          if(input.value.length == 0) {
            this.lockedSlaves.splice(this.lockedSlaves.indexOf(input), 1)
          }
          return
        }
        if(input.value != this.masterInputTarget.value) {
          this.lockedSlaves.push(input)
        }
      }.bind(this))
    })

    if (this.hasSubmitInputTarget) {
      this.toggleDisable()
      this.requiredInputTargets.forEach((input) => {
        input.addEventListener('change', this.toggleDisable)
        input.addEventListener('keyup', this.toggleDisable)
      })
    }
  }

  disconnect() {
  }

  prefillSlaves () {
    this.slaveInputTargets.forEach((input) => {
      if(this.lockedSlaves.indexOf(input) >= 0) {
        return
      }

      input.value = this.masterInputTarget.value
      const event = new Event('change')
      input.dispatchEvent(event)
    })
  }

  toggleDisable() {
    let canSubmit = true
    this.requiredInputTargets.forEach((input) => {
      canSubmit = canSubmit && this.inputIsFilled(input)
    })

    this.submitInputTarget.disabled = !canSubmit
  }

  inputIsFilled(input) {
    return input.value.length > 0
  }
}
