import { Controller } from "stimulus"
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = []

  connect() {
    this.enableSentryTracking()
  }

  enableSentryTracking() {
    Sentry.init({
      environment: this.data.get("environment"),
      dsn: this.data.get("dsn"),
      release: this.data.get("release")
    });

    window.Sentry = Sentry
  }
}