import { Controller } from "stimulus";

import motion from 'motion'

export default class extends Controller {

  connect() {
    this.initialize = this.initialize.bind(this)
    this.motionConnected = this.motionConnected.bind(this)

    this.element.addEventListener('motion:connect', this.motionConnected)
  }

  disconnect() {}

  motionConnected() {
    requestAnimationFrame(this.initialize)
  }

  initialize() {
  }

  stopEvent(e) {
    e.preventDefault()
    e.stopImmediatePropagation()
  }
}